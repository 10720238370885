<template>
    <div class="pano-container">
        <div id="webapp-view-pano" class="webapp-pano-view-container">
            <noscript>
                <table style="width:100%;height: 100%;">
                    <tr style="vertical-align:middle;">
                        <td>
                            <div style="text-align:center;">
                                ERROR:<br /><br />Javascript not activated<br /><br />
                            </div>
                        </td>
                    </tr>
                </table>
            </noscript>
        </div>
        <!-- <Jump></Jump> -->
       
        <!-- <ToolScenes></ToolScenes> -->
    </div>
</template>
<script>
// import Jump from './components/jump'
import ToolScenes from './components/tool-scenes'
import { myPanoProject } from 'api/pano/pano'
import { mapActions } from 'vuex'

export default {
  components: {
    ToolScenes,
    // Jump,
  },
  mounted() {
    window.hotspotGoScene = this.hotspotGoScene
    myPanoProject(this.$route.params.id).then(o => {
      this.project = {
        cover: o.cover,
        name: o.name,
        id: o.id,
        description: o.description,
        xml: o.xml,
      }
      this.projectScenes = [...o.scenes]
      let settings = {}
      settings['onstart'] = `loadxml('${o.xml}')`
      embedpano({
        xml: '',
        target: 'webapp-view-pano',
        html5: 'auto',
        mobilescale: 1.0,
        passQueryParameters: true,
        vars: settings,
        onready: this.onready,
      })
    })
  },
  methods: {
    ...mapActions('webappPanoModule', ['initKrpano', 'onHotspotGoScene']),
    onready(kp) {
      this.initKrpano({
        kp: kp,
        project: this.project,
        scenes: this.projectScenes,
      })
    },
    hotspotGoScene(sceneId, toSceneId, hotspotId) {
      console.log(toSceneId)
      this.onHotspotGoScene({
        sceneId,
        toSceneId,
        hotspotId,
      })
    },
  },
  data() {
    return {
      project: {},
      projectScenes: [],
    }
  },
}
</script>
<style lang="scss" scoped>

.pano-container {
  // background: url('../../../assets/cloud.png') no-repeat;
  // background-size: 100% 100%;
  height: 100vh;
  widows: 100vw;
  position: relative;
  .webapp-pano-view-container {
    height: 100vh;
    widows: 100vw;
  }
}
</style>
